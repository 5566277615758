@import '../../styles/base';

.page-title-bar {
  padding: 32px 88px 32px;
  background-color: rgba(#fff, 0.5);
  border-bottom: 1px solid #eaeff8;

  .container {
    max-width: 1280px;
    margin: auto;
  }

  h2 {
    font-size: 26px;
    font-weight: bold;
    color: $main-header-color;
  }
}
