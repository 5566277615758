@import '../../styles/base';

.datepicker {
  display: inline-block;
  width: 195px;
  position: relative;

  input {
    width: 100%;
    &:disabled {
      background: $form-disabled;
      color: $form-stroke-disabled;
    }
  }

  .calendar-input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;

    &::-webkit-calendar-picker-indicator {
      cursor: pointer;
    }
  }

  svg {
    position: absolute;
    pointer-events: none;
  }

  .chevron {
    top: calc(50% - 2px);
    right: 45px;
  }

  .calendar {
    top: calc(50% - 17px);
    right: 16px;
  }
}
