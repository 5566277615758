@import '../../styles/base';

.pagination {
  list-style: none;
  display: inline-block;
  height: 32px;
  padding: 0;
  border: 1px solid #d7d9de;
  border-radius: 4px;
  overflow: hidden;

  li {
    display: inline-block;
    font: normal 14px $font-family;
    height: 100%;
    padding: 4px 12px;
    color: #0064d2;
    background: #ffffff;
    cursor: pointer;
    user-select: none;

    &:not(:last-child) {
      border-right: 1px solid #d7d9de;
    }

    &.current {
      background: #effaff;
    }

    &.disabled {
      color: #ccced3;
      cursor: not-allowed;
      background: #f6f8fd;
    }
  }
}
