@import '../../styles/base';

.pagination {
  margin: 16px 0;

  &:first-child {
    margin-top: -12px;
  }
}

.table {
  tr {
    th,
    td {
      & {
        word-break: break-all;
      }

      &.indicator {
        width: 40px;
        padding: 16px 12px;
      }

      &.transaction-id,
      &.won {
        min-width: 250px;
      }

      &.amount,
      &.installment,
      &.status {
        min-width: 150px;
      }

      &.column-right {
        text-align: right;
      }
    }
  }

  tbody tr td {
    height: 84px;

    &.indicator {
      &.item {
        background: $table-header-bg;
      }

      svg {
        margin-top: 20px;
        color: #496792;
      }
    }

    &.transaction-id b {
      display: block;
      margin-bottom: 3px;
    }
  }
}

.transaction-id {
  font: 400 16px/24px $font-family-mono;
  margin-bottom: 5px;
}

.payment-link {
  display: block;
  padding-left: 22px;
  color: $text;
  background: url('/public/assets/linkto-arrow.svg') left center no-repeat;
  background-size: 16px 16px;

  &:hover {
    text-decoration: underline;
  }

  + :global(.payment-span) {
    margin-top: 10px;
    margin-left: 22px;
  }
}

.rejection-reason {
  display: block;
  margin-top: 8px;
  margin-left: 22px;
  background: #ff6262;
  border-radius: 4px;
  color: #fff;
  padding: 4px 20px 4px 8px;
  font-size: 15px;
  letter-spacing: -0.7px;
  line-height: 19px;
  max-width: 304px;
  max-height: 27px;
  position: relative;
  overflow: hidden;

  p {
    word-break: break-word;
  }

  &.expand {
    max-height: none;

    .btn-toggle {
      transform: rotate(180deg);
    }
  }

  .btn-toggle {
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 10px;
    display: block;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 6px solid #fff;

    &::after {
      position: absolute;
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      left: -8px;
      top: -8px;
    }
  }
}

.no-result {
  text-align: center;
}
