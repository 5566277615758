@import '../../styles/base';

.settings {
  display: flex;
}

.settings-tile {
  display: inline-flex;
  flex-direction: column;
  margin: 0 16px 16px 0;
  border-radius: 6px;
  padding: 24px;
  width: 360px;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
  min-height: 180px;
  vertical-align: middle;

  h3 {
    margin-bottom: 16px;
    font-size: 16px;
    color: $table-label;
  }

  p {
    margin-top: 16px;
    margin-bottom: 16px;
    font-size: 14px;
  }

  div:first-child {
    flex-grow: 1;
  }
}

.dialog {
  min-width: 460px;

  h3 {
    margin-bottom: 16px;
    font-size: 20px;
    color: $table-label;
  }

  span {
    font-size: 14px;
  }

  fieldset {
    margin-top: 12px;
  }

  label {
    margin-bottom: 8px;
    font-size: 14px;
    display: block;
  }

  .select {
    width: 100%;
  }

  input[type='submit'],
  button {
    width: 100%;
  }

  .button-cancel {
    width: 100%;
    &:not(:disabled) {
      background-color: #6d6d6d;
    }
  }

  .form-group {
    margin-top: 24px;
  }
}

.dialog {
  min-width: 460px;

  h3 {
    margin-bottom: 16px;
    font-size: 20px;
    color: $table-label;
  }

  span {
    font-size: 14px;
  }

  fieldset {
    margin-top: 12px;
  }

  label {
    margin-bottom: 8px;
    font-size: 14px;
    display: block;
  }

  .select {
    width: 100%;
  }

  input[type='submit'],
  button {
    width: 100%;
  }

  .button-cancel {
    width: 100%;
    &:not(:disabled) {
      background-color: #6d6d6d;
    }
  }

  .form-group {
    margin-top: 24px;
  }
}

.error-msg {
  margin: 16px 0;
  min-height: 48px;
  font-size: 16px;
  color: $status-red;
  text-align: center;
}

.success-msg {
  text-align: center;
}
