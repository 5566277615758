@import '../../styles/base';

.badge {
  text-transform: uppercase;
  font: bold 11px $font-family;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  .circle {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 4px;
  }

  &.info {
    color: #4a92d0;
    .circle {
      background: #4a92d0;
    }
  }

  &.success {
    color: #8bc34a;
    .circle {
      background: #8bc34a;
    }
  }

  &.danger {
    color: #ff6262;
    .circle {
      background: #ff6262;
    }
  }
}
