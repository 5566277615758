@import '../../styles/base';

.root {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(42, 46, 50, 0.3);
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: scroll;
  padding: 24px;
}

.content {
  position: relative;
  margin: auto;
  background: #fff;
  padding: 24px;
  border-radius: 6px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
}
