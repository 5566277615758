@import '../../styles/base';

.search-form {
  margin-top: 16px;
  display: flex;
  flex-direction: column;

  .submit {
    margin-left: 8px;
  }

  .result-filter,
  .date-range {
    .select-container {
      position: relative;
      min-width: 250px;

      &:not(:last-child) {
        margin-right: 16px;
      }

      select {
        box-sizing: border-box;
        appearance: none;
        border-radius: 4px;
        border: 1px solid #000;
        transition: border-color 0.3s ease-in-out;
        font: 400 14px/38px $font-family;
        width: 100%;
        padding: 0 12px;

        &:focus {
          border-color: $form-stroke-active;
        }
      }

      svg {
        position: absolute;
        top: calc(50% - 5px);
        right: 16px;
      }

      &:nth-child(2) {
        width: 100%;
      }
    }

    input,
    .select-container:nth-child(2) {
      margin-right: 106px;

      &:disabled {
        border-color: #d7d9de;
        // color: #ccced3;
      }
    }

    b {
      padding: 0 16px;

      &.disabled {
        color: #ccced3;
      }
    }
  }

  .result-filter,
  .date-range {
    margin-top: 16px;
  }

  .search-items,
  .result-filter,
  .date-range {
    display: flex;
    margin-left: 62px;
    align-items: center;
  }
}
