@import '../../styles/base';

.pill {
  display: inline-block;
  min-width: 100px;
  height: 22px;
  padding: 4px 16px;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  font: bold 14px $font-family;
  line-height: 12px;
  text-transform: capitalize;
  text-align: center;

  &.info {
    background: $status-blue-bg;
    border-color: $status-blue;
    color: $status-blue;
  }

  &.danger {
    background: $status-red-bg;
    border-color: $status-red;
    color: $status-red;
  }
}
