@import '../../styles/base';

.primary {
  display: inline-block;
  border-radius: 4px;
  padding: 0 22px;
  width: auto;
  font: bold 16px/40px $font-family;
  text-align: center;
  color: #fff;

  &:not(:disabled) {
    background-color: $dark-navy;
  }
}

.secondary {
  display: inline-block;
  border-radius: 20px;
  padding: 0 26px;
  width: auto;
  font: bold 14px/32px $font-family;
  color: #fff;

  &:not(:disabled) {
    background-color: $dark-navy;
  }
}
