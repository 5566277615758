@import './styles/reset';
@import './styles/base';
@import './styles/form';
@import './styles/noto';

html,
#root {
  width: 100%;
  height: 100%;
  min-width: 1214px;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font: 400 16px/1.4 $font-family;
  background: #f2f5fc;
  color: $text;
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;

  &:focus {
    outline: 0;
  }
}

.container {
  max-width: 1280px;
  margin: 0 auto;
}

a {
  text-decoration: none;
  color: #3f51b5;
}

b {
  font-weight: bold;
}

h3 {
  font: bold 24px/1.5 $font-family;
  margin-bottom: 16px;
}

h4 {
  font: bold 20px/1.5 $font-family;
  margin-bottom: 16px;
}

ul {
  list-style: decimal;
  padding-left: 24px;
}

.noto-sans {
  font-family: 'Noto Sans JP', sans-serif !important;
}

.bold {
  font-weight: bold;
}

.align-right {
  text-align: right !important;
}

.tx-span {
  font: bold 11px/20px $font-family;
  text-align: center;
  display: inline-block;
  border: 1px solid black;
  border-radius: 4px;
  width: 84px;
  text-transform: uppercase;

  &.create {
    border-color: $status-blue;
    color: $status-blue;
    background-color: $status-blue-bg;
  }

  &.capture {
    border-color: $status-green;
    color: $status-green;
    background-color: $status-green-bg;
  }

  &.refund,
  &.cancel {
    border-color: $status-red;
    color: $status-red;
    background-color: $status-red-bg;
  }
}

.payment-span,
.reg-span {
  display: inline-block;
  position: relative;
  padding-left: 14px;
  // font-size: 11px;
  // font-weight: bold;
  text-transform: uppercase;
  font: bold 11px/1 $font-family;

  &::before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    background-color: transparent;
    left: 0;
    border-radius: 50%;
    @extend %vertical-center;
  }

  &.created,
  &.authorized,
  &.authenticated {
    color: $status-blue;

    &::before {
      background-color: $status-blue;
    }
  }

  &.rejected,
  &.suspended,
  &.deleted {
    color: $status-red;

    &::before {
      background-color: $status-red;
    }
  }

  &.closed,
  &.active {
    color: $status-green;

    &::before {
      background-color: $status-green;
    }
  }
}
